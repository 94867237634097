export const bootWords = [
  "Hello",
  "こんにちは",
  "ನಮಸ್ಕಾರ",
  "నమస్కారం",
  "Bonjour",
  "안녕하세요",
  "Hallo",
  "नमस्ते",
  "Ciao",
]; 